<template>
    <div class="pa-2 pa-sm-4 rounded-lg light-grey-border white-background doctor-component">
        <div class="d-flex flex-column justify-space-between">
            <img class="rounded doctor-image" :src="doctor_data.icon" alt="Product Image">

            <div class="pt-2 pt-sm-4 doctor-info-container">
                <div class="d-flex-column justify-flex-start">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <p class="ma-0 body-heading font-weight-semi-bold line-clamp doctor-name"
                                v-bind="attrs"
                                v-on="on"
                                align="left"
                            >{{ doctor_data.name }}</p>
                        </template>
                        <span>{{ doctor_data.name }}</span>
                    </v-tooltip>
                    <!-- <p class="" align="left">{{ doctor_data.name }}</p> -->
                </div>
                
                <div class="pt-2 d-flex flex-column justify-center bottom-div">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <p class="ma-0 body-text light-grey-color-text font-weight-medium line-clamp-one-line"
                                v-bind="attrs"
                                v-on="on"
                                align="left"
                            >{{ doctor_data.specialization_name }}</p>
                        </template>
                        <span>{{ doctor_data.specialization_name }}</span>
                    </v-tooltip>
                    <p class="ma-0 pb-2 body-heading font-weight-semi-bold light-blue-color-text" align="left">{{ doctor_data.price }} KS</p>
                    <button class="pa-2 rounded light-blue-background button-class" @click="redirectTo('newBookingPage')">
                        <span class="body-heading font-weight-semi-bold white-color-text">{{ $t("Customer.ViewDoctorsPage.View_Slot") }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DoctorHomeComponent',
    props: {
        doctor_data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            screenWidth: window.innerWidth,
        };
    },
    mounted() {
        this.screenWidth = window.innerWidth;
        window.addEventListener('resize', this.handleResize);      
    },
    beforeUnmount() {
		window.removeEventListener('resize', this.handleResize);
	},
    methods: {
        handleResize() {
			console.log('Handle Resize method called');
            this.screenWidth = window.innerWidth;

            this.$emit('handleComponentSizeChange');
		},
        redirectTo(pageName) {
            if (this.screenWidth <= 600) {
                this.$router.push({
                    path: '/customer/newBookingPage/' + this.doctor_data.id + '&Booking'
                });
            } else {
                if (pageName == 'newBookingPage') {
                    window.open(window.location.origin + '/customer/newBookingPage/' + this.doctor_data.id + '&' + 'Booking');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';
.doctor-component {
    height: 100%;

    @media (max-width: 600px) {
        width: 138px !important;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        width: 218px !important;
        border-radius: 8px;
    }

    @media (min-width: 1265px) {
        width: 218px !important;
        border-radius: 8px;
    }
}

.doctor-image {
    object-fit: fill;

    @media (max-width: 600px) {
        height: 120px;
    }

    @media (min-width: 601px) and (max-width: 1264px) {
        height: 180px;
    }

    @media (min-width: 1265px) {
        height: 180px;
    }
}

.doctor-name {
    width: 100%;
    height: 52px;
}

.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-one-line {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>

<style lang="scss">

</style>